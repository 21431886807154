<template>
  <div>

    <b-form-group
      v-if="label!=''"
      label-cols-md="4"
      :label="label"
      :description="desc"
    >
      
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <b-form-file
          ref="file"
          :value="value"
          placeholder="Search File Upload"
          drop-placeholder="Drop file here..."
          @input="handleInput"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-file
      v-else
      ref="file"
      :value="value"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      @input="handleInput"
    />
  </div>
</template>

<script>
import {BFormFile, BFormGroup} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
  export default {
    components: {
      BFormFile,
      BFormGroup,
      ValidationProvider,
    },
    props: {
      value: {
        type: File,
        default: null
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      }
    },
    methods: {
      handleInput(e) {
        this.$emit('input', e)
      },
      reset(){
        this.$refs['file'].reset()
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>