<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col>
            <br>
            <form-input
              v-model="code"
              rules="required"
              label="Kode"
              placeholder="Kode Karyawan"
            />
            <form-input
              v-model="name"
              rules="required"
              label="Nama"
              placeholder="Nama Pegawai"
            />
            <form-input
              v-model="address"
              rules="required"
              label="Alamat"
              placeholder="Alamat"
            />
            <!-- <form-v-select
              ref="city"
              v-model="city"
              :selected="city"
              label="Kota"
              rules="required"
              placeholder="Select Value"
              :options="optionCity"
            /> -->
            <form-autocomplate
              :value-key="city"
              :value="city"
              label="Kota"
              placeholder="Masukkan Kota"
              index-value="name"
              dataUrl="/city?search="
              objectView="data"
              @input="changeInput"
            />
            <form-input
              v-model="email"
              rules="required"
              label="Email"
              placeholder="Email"
            />
            <form-input
              v-model="phone"
              rules="required"
              label="Handphone"
              placeholder="Mobile"
            />
            <hr>
            <form-v-select
              ref="staff"
              v-model="staff"
              :selected="staff"
              label="Jabatan/Posisi"
              rules="required"
              placeholder="Select Value"
              :options="optionStaff"
              @input="changeSelectStaff"
            />
            <form-v-select
              v-if="(['pusat', 'region', 'area', 'ulp', 'kantorjaga']).includes(staff_level)"
              ref="region"
              v-model="region"
              :selected="region"
              label="Region"
              rules="required"
              placeholder="Select Value"
              :options="optionRegion"
              @input="changeSelectRegion"
            />
            <form-v-select
              v-if="(['area', 'ulp', 'kantorjaga']).includes(staff_level)"
              ref="area"
              v-model="area"
              :selected="area"
              label="Area Layanan"
              rules="required"
              placeholder="Select Value"
              :options="optionArea"
              @input="changeSelectArea"
            />
            <form-v-select
              v-if="(['ulp', 'kantorjaga']).includes(staff_level)"
              ref="ulp"
              v-model="ulp"
              :selected="ulp"
              label="U L P"
              rules="required"
              placeholder="Select Value"
              :options="optionULP"
              @input="changeSelectUlp"
            />
            <form-v-select
              v-if="(['kantorjaga']).includes(staff_level)"
              ref="kantorjaga"
              v-model="kantorjaga"
              :selected="kantorjaga"
              label="Kantor Jaga"
              rules="required"
              placeholder="Select Value"
              :options="optionKantorJaga"
            />
            <form-v-select
              ref="project"
              v-model="project"
              :selected="project"
              rules="required"
              label="Project"
              placeholder="Select Value"
              :options="optionProject"
            />

            <hr>

            <form-file
              v-model="file_ttd"
              required=""
              label="Upload TTD"
              placeholder="Upload Image TTD"
            />

            <b-form-group
                label-cols-md="4"
                label=""
              >
                <!-- <p><strong>Akun Aplikasi</strong></p>
                <p>User name yang digunakan adalah email yang dimasukkan</p> -->
              </b-form-group>
              <b-form-group
                label-cols-md="4"
                label=""
              >
                <b-form-checkbox
                  v-model="is_ttd_spb"
                  switch
                >Set Tanda Tangan SPB</b-form-checkbox>
              </b-form-group>

            <div v-if="!this.$route.params.id">
              <hr>
              <b-form-group
                label-cols-md="4"
                label=""
              >
                <p><strong>Akun Aplikasi</strong></p>
                <p>User name yang digunakan adalah email yang dimasukkan</p>
              </b-form-group>
              <b-form-group
                label-cols-md="4"
                label=""
              >
                <b-form-checkbox
                  v-model="is_aplikasi"
                  switch
                >Daftarkan Akses aplikasi</b-form-checkbox>
              </b-form-group>
              <form-v-select
                ref="level"
                v-model="level"
                label="Level"
                placeholder="Select Value"
                :options="optionLevel"
                :disabled="!is_aplikasi"
              />
              <form-input
                v-model="password"
                label="Password"
                placeholder="Password"
                :type="'password'"
                :disabled="!is_aplikasi"
              />
              <form-input
                v-model="retype_password"
                label="Ulang Password"
                placeholder="Ulang Password"
                :type="'password'"
                :disabled="!is_aplikasi"
              />
            </div>
            <hr>
            <b-form-group
              label-cols-md="4"
              label=""
            >
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                type="button"
                @click.prevent="validationForm"
              >
                <span v-if="loading">
                  <b-spinner small />
                  Loading...
                </span>
                <span v-else>Simpan</span>
              </b-button>
              <b-button
                class="mr-1"
                size="sm"
                variant="warning"
                type="button"
                @click.prevent="batal"
              >
                <span>Batal</span>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, integer, between } from 'vee-validate/dist/rules';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {BCard, BCol, BRow, BButton, BBadge, BForm, BFormGroup, BFormCheckbox, BSpinner} from 'bootstrap-vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormSelect from '@/views/base/form/FormSelect.vue'
import FormAutocomplate from '@/views/base/form/FormAutocomplate.vue'
import FormFile from '@/views/base/form/FormFile.vue'

extend('required', required)

export default {
  components: {
    // ValidationProvider, 
    ValidationObserver,
    required,
    BCard,
    BCol, 
    BRow, 
    BForm, 
    BButton,
    BFormGroup,
    FormInput,
    FormVSelect,
    BFormCheckbox,
    BSpinner,
    FormAutocomplate,
    FormFile
  },
  data() {
    return {
      loading :false,
      title:'Tambah Data Karyawan',
      posturl:'/staff',
      optionCity:[],
      optionStaff:[],
      optionRegion:[],
      optionArea:[],
      optionULP:[],
      optionKantorJaga:[],
      optionProject:[],
      optionLevel:[],
      staff_level:"",
      code:"",
      name:"",
      address:"",
      city:"",
      email:"",
      phone:"",
      staff:null,
      region:null,
      area:null,
      ulp:null,
      kantorjaga:null,
      project:null,
      is_aplikasi:false,
      level:null,
      password:"",
      retype_password:"",
      branch_id:"",
      file_ttd:null,
      is_ttd_spb:false
    }
  },
  mounted(){
    // this.fetchDataCity()
    this.fetchDataStaff()
    // this.fetchDataRegion()
    this.fetchDataProject()

    if(this.$route.params.id){
      this.fetchShowData()
    }else{
      this.fetchDataRole()
    }
  },
  methods:{
    batal(){
      this.$router.go(-1)
    },
    fetchShowData(){
      this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
        let data = res.data
        this.code = data.code
        this.name = data.name
        this.address = data.address
        this.phone = data.phone
        this.email = data.email
        this.staff = data.job_title
        this.region = data.region
        this.area = data.area
        this.ulp = data.ulp
        this.kantorjaga = data.kantorjaga
        this.staff_level = data.level
        this.city = data.city
        this.project = data.project
        this.is_ttd_spb = data.is_ttd_spb


        if(data.kantorjaga){
          this.staff_level = "kantorjaga"
        }else if(data.ulp){
          this.staff_level = "ulp"
        }else if(data.area){
          this.staff_level = "area"
        }else if(data.region){
          this.staff_level = "region"
        }

        if(data.kantorjaga){
          this.fetchDataKantorJaga(data.ulp.id)
        }
        if(data.ulp){
          this.fetchDataUlp(data.area.id)
        }
        if(data.area){
          this.fetchDataArea(data.region.id)
        }
        if(data.region){
          this.fetchDataRegion()
        }
      })
    },
    fetchDataCity(){
      this.$http.get('city?length=').then(res=>{
        this.optionCity = res.data.data
        this.$refs.city.refreshData(this.optionCity)
      })
    },
    fetchDataStaff(){
      this.$http.get('job-title?length=').then(res=>{
        this.optionStaff = res.data.data
        this.$refs.staff.refreshData(this.optionStaff)
      })
    },
    fetchDataRegion(){
      this.$http.get('region?length=').then(res=>{
        this.optionRegion = res.data.data
        this.$refs.region.refreshData(this.optionRegion)
      })
    },
    fetchDataArea(parent_id){
      var params = {}
      if(parent_id){
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-area?length=', {params:params}).then(res=>{
        this.optionArea = res.data.data
        this.$refs.area.refreshData(this.optionArea)
      })
    },
    fetchDataUlp(parent_id){
      var params = {}
      if(parent_id){
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-center?length=', {params:params}).then(res=>{
        this.optionULP = res.data.data
        this.$refs.ulp.refreshData(this.optionULP)
      })
    },
    fetchDataKantorJaga(parent_id){
      var params = {}
      if(parent_id){
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-guard?length=', {params:params}).then(res=>{
        this.optionKantorJaga = res.data.data
        this.$refs.kantorjaga.refreshData(this.optionKantorJaga)
      })
    },
    fetchDataProject(){
      this.$http.get('project?length=').then(res=>{
        this.optionProject = res.data.data
        this.$refs.project.refreshData(this.optionProject)
      })
    },
    fetchDataRole(){
      this.$http.get('role?length=').then(res=>{
        this.optionLevel = res.data.data
        this.$refs.level.refreshData(this.optionLevel)
      })
    },
    changeSelectStaff(data){
      if (data){
        this.staff_level = data.level
        this.fetchDataRegion()
      }else{
        this.staff_level = null
      }
      this.region = null
      this.area   = null
      this.ulp    = null
      this.kantorjaga = null
    },
    changeSelectRegion(data){
      if(data != null){
        this.branch_id = data.id
        if ((['area', 'ulp', 'kantorjaga']).includes(this.staff_level))
          this.fetchDataArea(data.id)
      }
      this.area   = null
      this.ulp    = null
      this.kantorjaga = null
    },
    changeSelectArea(data){
      if(data != null){
        this.branch_id = data.id
        if ((['ulp', 'kantorjaga']).includes(this.staff_level))
          this.fetchDataUlp(data.id)
      }
      this.ulp    = null
      this.kantorjaga = null
    }
    ,
    changeSelectUlp(data){
      if(data != null){
        this.branch_id = data.id
        if ((['kantorjaga']).includes(this.staff_level))
          this.fetchDataKantorJaga(data.id)
      }
      this.kantorjaga = null
    },
    changeInput(val){
      this.city = val.value
    },
    async validationForm() {
      this.loading = true
      var path = null
      if(this.file_ttd){
        let formData = new FormData()
        formData.append('file', this.file_ttd)
        let res = await this.$http.post('upload/image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch( err => {
          this.loading = false
          const msg = err.response.data.message
          return this.$bvToast.toast((msg)?msg:'File not found', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })
        })

        path = res.data.path
      }

      this.$refs.simpleRules.validate().then( res => {
        if(res){
          if(this.is_aplikasi && this.retype_password != this.password){
            return this.$bvToast.toast('Please correct form input password retype', {
              title: 'Password Error',
              solid: true,
              variant:'danger'
            })
          }

          this.loading = true
          let params = {
            code: this.code,
            name: this.name,
            address: this.address,
            city: this.city,
            postal_code: "-",
            phone: this.phone,
            email: this.email,
            photo: "",
            job_title_id: this.staff.id,
            region_id: this.region != null?this.region.id:null,
            service_area_id: this.area != null?this.area.id:null,
            service_center_id: this.ulp != null?this.ulp.id:null,
            service_guard_id: this.kantorjaga != null?this.kantorjaga.id:null,
            project_id: this.project != null?this.project.id:null,
            level: this.staff_level,
            create_user: this.is_aplikasi,
            password: this.password,
            role_id: this.level ? this.level.id : null,
            is_ttd_spb: this.is_ttd_spb,
            file_ttd: path
          }
          if(!this.$route.params.id)
            this.$http.post('staff', params)
            .then(()=>{
              this.successSubmit()
            })
            .catch(err=>{
              this.errorSubmit(err)
            })
            .finally(() => {this.loading = false})
          else
            this.$http.put(this.posturl+'/'+this.$route.params.id, params)
            .then(()=>{
              this.successSubmit()
            })
            .catch(err=>{
              this.errorSubmit(err)
            })
            .finally(() => {this.loading = false})
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      this.$router.go(-1)
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  }
}
</script>