<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      :label-cols-md="label == ''?'0':colLabel"
      :label="label"
      :description="desc"
    >
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <v-select
          :value="value"
          :label="item_text"
          :placeholder="placeholder"
          :options="dataOptions"
          items-value="id"
          :disabled="disabled"
          @input="handleInput"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormSelect, BFormGroup} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
  export default {
    components: {
      // BFormSelect,
      BFormGroup,
      ValidationProvider,
      vSelect
    },
    props: {
      value:{
        type: Object,
        default() {
          return null
        },
      },
      item_text:{
        type: String,
        default: 'name'
      },
      label: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default() {
          return []
        },
      },
      disabled: {
        type: Boolean,
        default: false
      },
      dataurl: {
        type: String,
        default: ''
      },
      colLabel:{
        type: String,
        default: "4"
      }
    },
    data(){
      return{
        dataOptions:[]
      }
    },
    created(){
      if(this.options)
        this.dataOptions = this.options
    },
    mounted(){
      if(this.dataurl != ''){
        this.getData()
      }
    },
    methods: {
      refreshData(data){
        this.dataOptions = data
      },
      getData(){
        var params ={}
        params["length"] = ''
        this.$http.get(this.dataurl, {params:params}).then(res=>{
          this.dataOptions = res.data.data
        })
      },
      handleInput(e) {
        this.$emit('input', e)
      }
    },
  }
  
</script>