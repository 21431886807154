var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label-cols-md":_vm.label == ''?'0':'4',"label":_vm.label,"description":_vm.desc}},[_c('validation-provider',{attrs:{"name":_vm.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-simple-suggest',{ref:"suggestComponent",attrs:{"pattern":"\\w+","display-attribute":_vm.indexValue,"value-attribute":_vm.indexValue,"min-length":_vm.minLength,"debounce":200,"list":_vm.getList,"controls":{
          selectionUp: [38],
          selectionDown: [40],
          select: [13],
          showList: [40],
          hideList: [27],
          autocomplete: [32, 13]
        },"state":errors.length > 0 ? false:null},on:{"select":_vm.onSuggestSelect,"input":_vm.handleInput},scopedSlots:_vm._u([{key:"misc-item-above",fn:function(ref){
        var suggestions = ref.suggestions;
        var query = ref.query;
return [_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("You're searching for '"+_vm._s(query)+"'.")])]),(suggestions.length > 0 || _vm.loading)?[(!_vm.loading)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v(_vm._s(suggestions.length)+" suggestions are shown...")])]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"misc-item",attrs:{"slot":"misc-item-below"},slot:"misc-item-below"},[_c('span',[_vm._v("Loading...")])]):_vm._e(),_c('hr')]:(!_vm.loading)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("No results")])]):_vm._e()]}},{key:"suggestion-item",fn:function(scope){return _c('div',{attrs:{"title":scope.suggestion.description}},[_c('div',{staticClass:"text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.boldenSuggestion(scope))}})])])}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('div',{staticClass:"g"},[_c('input',{attrs:{"type":"text","placeholder":_vm.placeholder,"state":errors.length > 0 ? false:null},domProps:{"value":_vm.value}})])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }