<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      :label-cols-md="label == ''?'0':'4'"
      :label="label"
      :description="desc"
    >
      
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <!-- <b-form-input
          :value="value"
          :placeholder="placeholder"
          :state="errors.length > 0 ? false:null"
          :type="type"
          :disabled="disabled"
          @input="handleInput"
        /> -->
        <vue-simple-suggest
          ref="suggestComponent"
          v-model="value"
          pattern="\w+"
          :display-attribute="indexValue"
          :value-attribute="indexValue"
          :min-length="minLength"
          :debounce="200"
          :list="getList"
          :controls="{
            selectionUp: [38],
            selectionDown: [40],
            select: [13],
            showList: [40],
            hideList: [27],
            autocomplete: [32, 13]
          }"
          :state="errors.length > 0 ? false:null"
          @select="onSuggestSelect"
          @input="handleInput"
        >

          <!-- <b-form-input
            :value="value"
            type="text"
          /> -->
          <div class="g">
            <input
              type="text"
              :value="value"
              :placeholder="placeholder"
              :state="errors.length > 0 ? false:null"
            >
          </div>

          <template
            slot="misc-item-above"
            slot-scope="{ suggestions, query }"
          >
            <div class="misc-item">
              <span>You're searching for '{{ query }}'.</span>
            </div>

            <template v-if="suggestions.length > 0 || loading">
              <div
                v-if="!loading"
                class="misc-item"
              >
                <span>{{ suggestions.length }} suggestions are shown...</span>
              </div>
              <div
                v-if="loading"
                slot="misc-item-below"
                class="misc-item"
              >
                <span>Loading...</span>
              </div>
              <hr>
            </template>

            <div
              v-else-if="!loading"
              class="misc-item"
            >
              <span>No results</span>
            </div>
          </template>

          <div
            slot="suggestion-item"
            slot-scope="scope"
            :title="scope.suggestion.description"
          >
            <div class="text">
              <span v-html="boldenSuggestion(scope)" />
            </div>
          </div>
        </vue-simple-suggest>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormInput, BFormGroup} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import numeral from 'numeral'
  export default {
    components: {
      BFormInput,
      BFormGroup,
      ValidationProvider,
      VueSimpleSuggest
    },
    props: {
      valueKey:{
        type:String,
        default:''
      },
      value: {
        type: String,
        default: ''
      },
      indexValue:{
        type:String,
        default:'display_name'
      },
      size: {
        type: String,
        default: '8'
      },
      desc: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      dataUrl:{
        type:String,
        default:''
      },
      objectView:{
        type:String,
        default:''
      },
      minLength:{
        type:Number,
        default:2
      }
    },
    data(){
      return{
        loading:false
      }
    },
    methods: {
      boldenSuggestion (scope) {
        if (!scope) return scope
        const { suggestion, query } = scope
        const result = this.$refs.suggestComponent.displayProperty(suggestion)
        if (!query) return result
        const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || ['']
        return result.replace(new RegExp(`(.*?)(${  texts.join('|')  })(.*?)`, 'gi'), '$1<b>$2</b>$3')
      },
      async getList (inputValue) {
        this.value = inputValue
        this.loading = true
        if(this.dataUrl == '' || this.dataUrl == null)
          this.dataUrl = 'https://nominatim.openstreetmap.org/search?format=jsonv2&q='

        let { data } = await this.$http.get(this.dataUrl+inputValue)
        if(this.objectView != ''){
          data = data[this.objectView]
        }
        this.loading = false
        return data
      },
      onSuggestSelect(e){
        e.valueKey = this.valueKey
        this.$emit('change', e)
      },
      handleInput(e){
        let val = {value: e, valueKey: this.valueKey}
        this.$emit('input', val)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>