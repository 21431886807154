<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      :label-cols-md="label == ''?'0':'4'"
      :content-cols-md="size"
      :label="label"
      :description="desc"
    >
      
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <b-form-input
          :value="type=='number'?parseInt(value!=''?value:'0'):value"
          :placeholder="placeholder"
          :state="errors.length > 0 ? false:null"
          :type="type"
          :disabled="disabled"
          @input="handleInput"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormInput, BFormGroup} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
  export default {
    components: {
      BFormInput,
      BFormGroup,
      ValidationProvider,
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: '8'
      },
      label: {
        type: String,
        default: 'LabelName'
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: "text"
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleInput(e) {
        this.$emit('input', e)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>